import { default as indexrEJnnnNfOlMeta } from "F:/NodejsProject/Hpapi9/nuxt_server/pages/index.vue?macro=true";
import { default as cardmi9VDAZgKI4tMeta } from "F:/NodejsProject/Hpapi9/nuxt_server/pages/manage/center/agent/cardmi.vue?macro=true";
import { default as dotrecordofagn0eqyVMeta } from "F:/NodejsProject/Hpapi9/nuxt_server/pages/manage/center/agent/dotrecord.vue?macro=true";
import { default as index1IXNUtnk13Meta } from "F:/NodejsProject/Hpapi9/nuxt_server/pages/manage/center/agent/index.vue?macro=true";
import { default as statisticsEYOiQntdBSMeta } from "F:/NodejsProject/Hpapi9/nuxt_server/pages/manage/center/agent/statistics.vue?macro=true";
import { default as usermanageK5P3ZvOcd7Meta } from "F:/NodejsProject/Hpapi9/nuxt_server/pages/manage/center/agent/usermanage.vue?macro=true";
import { default as appinfoMajnfElMiQMeta } from "F:/NodejsProject/Hpapi9/nuxt_server/pages/manage/center/app/appinfo.vue?macro=true";
import { default as netpanno3vCuqivTMeta } from "F:/NodejsProject/Hpapi9/nuxt_server/pages/manage/center/app/netpan.vue?macro=true";
import { default as datatpl_augKrSRftMS7RMeta } from "F:/NodejsProject/Hpapi9/nuxt_server/pages/manage/center/components/datatpl_aug.vue?macro=true";
import { default as datatpl_rocketLczSEVqAGnMeta } from "F:/NodejsProject/Hpapi9/nuxt_server/pages/manage/center/components/datatpl_rocket.vue?macro=true";
import { default as dlg_app_aedyVFrjQN83IMeta } from "F:/NodejsProject/Hpapi9/nuxt_server/pages/manage/center/components/dlg_app_aed.vue?macro=true";
import { default as dlg_appver_aedoqHp1bLUWkMeta } from "F:/NodejsProject/Hpapi9/nuxt_server/pages/manage/center/components/dlg_appver_aed.vue?macro=true";
import { default as dlg_cardmi_avdt2X5w6XSgZMeta } from "F:/NodejsProject/Hpapi9/nuxt_server/pages/manage/center/components/dlg_cardmi_avd.vue?macro=true";
import { default as dlg_cardmi_banoZUfMcOSscMeta } from "F:/NodejsProject/Hpapi9/nuxt_server/pages/manage/center/components/dlg_cardmi_ban.vue?macro=true";
import { default as dlg_cardmi_editeaSJTnSsHUMeta } from "F:/NodejsProject/Hpapi9/nuxt_server/pages/manage/center/components/dlg_cardmi_edit.vue?macro=true";
import { default as dlg_cardmi_handVFpoJUxeWZMeta } from "F:/NodejsProject/Hpapi9/nuxt_server/pages/manage/center/components/dlg_cardmi_hand.vue?macro=true";
import { default as dlg_cardmi_renewlZ6ya8sXbxMeta } from "F:/NodejsProject/Hpapi9/nuxt_server/pages/manage/center/components/dlg_cardmi_renew.vue?macro=true";
import { default as dlg_cardmi_transCe33S9OiwMMeta } from "F:/NodejsProject/Hpapi9/nuxt_server/pages/manage/center/components/dlg_cardmi_trans.vue?macro=true";
import { default as dlg_cardmi_unbindpGcZs3Ai8RMeta } from "F:/NodejsProject/Hpapi9/nuxt_server/pages/manage/center/components/dlg_cardmi_unbind.vue?macro=true";
import { default as dlg_leves_aed2dySRadz6OMeta } from "F:/NodejsProject/Hpapi9/nuxt_server/pages/manage/center/components/dlg_leves_aed.vue?macro=true";
import { default as dlg_paramtemp_aedC0SdDy8HewMeta } from "F:/NodejsProject/Hpapi9/nuxt_server/pages/manage/center/components/dlg_paramtemp_aed.vue?macro=true";
import { default as dlg_umgt_edCJWbAh2d2UMeta } from "F:/NodejsProject/Hpapi9/nuxt_server/pages/manage/center/components/dlg_umgt_ed.vue?macro=true";
import { default as dlg_umgt_recharge243Nu0NL1qMeta } from "F:/NodejsProject/Hpapi9/nuxt_server/pages/manage/center/components/dlg_umgt_recharge.vue?macro=true";
import { default as dlg_umgt_regGalxqOTAm0Meta } from "F:/NodejsProject/Hpapi9/nuxt_server/pages/manage/center/components/dlg_umgt_reg.vue?macro=true";
import { default as indexoVX1qriezbMeta } from "F:/NodejsProject/Hpapi9/nuxt_server/pages/manage/center/index.vue?macro=true";
import { default as centerzbRsblcYQ9Meta } from "F:/NodejsProject/Hpapi9/nuxt_server/pages/manage/center.vue?macro=true";
import { default as indexV1tPV3NT5OMeta } from "F:/NodejsProject/Hpapi9/nuxt_server/pages/manage/index.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("F:/NodejsProject/Hpapi9/nuxt_server/pages/index.vue")
  },
  {
    name: centerzbRsblcYQ9Meta?.name,
    path: "/manage/center",
    component: () => import("F:/NodejsProject/Hpapi9/nuxt_server/pages/manage/center.vue"),
    children: [
  {
    name: "manage-center-agent-cardmi",
    path: "agent/cardmi",
    component: () => import("F:/NodejsProject/Hpapi9/nuxt_server/pages/manage/center/agent/cardmi.vue")
  },
  {
    name: "manage-center-agent-dotrecord",
    path: "agent/dotrecord",
    component: () => import("F:/NodejsProject/Hpapi9/nuxt_server/pages/manage/center/agent/dotrecord.vue")
  },
  {
    name: "manage-center-agent",
    path: "agent",
    component: () => import("F:/NodejsProject/Hpapi9/nuxt_server/pages/manage/center/agent/index.vue")
  },
  {
    name: "manage-center-agent-statistics",
    path: "agent/statistics",
    component: () => import("F:/NodejsProject/Hpapi9/nuxt_server/pages/manage/center/agent/statistics.vue")
  },
  {
    name: "manage-center-agent-usermanage",
    path: "agent/usermanage",
    component: () => import("F:/NodejsProject/Hpapi9/nuxt_server/pages/manage/center/agent/usermanage.vue")
  },
  {
    name: "manage-center-app-appinfo",
    path: "app/appinfo",
    component: () => import("F:/NodejsProject/Hpapi9/nuxt_server/pages/manage/center/app/appinfo.vue")
  },
  {
    name: "manage-center-app-netpan",
    path: "app/netpan",
    component: () => import("F:/NodejsProject/Hpapi9/nuxt_server/pages/manage/center/app/netpan.vue")
  },
  {
    name: "manage-center-components-datatpl_aug",
    path: "components/datatpl_aug",
    component: () => import("F:/NodejsProject/Hpapi9/nuxt_server/pages/manage/center/components/datatpl_aug.vue")
  },
  {
    name: "manage-center-components-datatpl_rocket",
    path: "components/datatpl_rocket",
    component: () => import("F:/NodejsProject/Hpapi9/nuxt_server/pages/manage/center/components/datatpl_rocket.vue")
  },
  {
    name: "manage-center-components-dlg_app_aed",
    path: "components/dlg_app_aed",
    component: () => import("F:/NodejsProject/Hpapi9/nuxt_server/pages/manage/center/components/dlg_app_aed.vue")
  },
  {
    name: "manage-center-components-dlg_appver_aed",
    path: "components/dlg_appver_aed",
    component: () => import("F:/NodejsProject/Hpapi9/nuxt_server/pages/manage/center/components/dlg_appver_aed.vue")
  },
  {
    name: "manage-center-components-dlg_cardmi_avd",
    path: "components/dlg_cardmi_avd",
    component: () => import("F:/NodejsProject/Hpapi9/nuxt_server/pages/manage/center/components/dlg_cardmi_avd.vue")
  },
  {
    name: "manage-center-components-dlg_cardmi_ban",
    path: "components/dlg_cardmi_ban",
    component: () => import("F:/NodejsProject/Hpapi9/nuxt_server/pages/manage/center/components/dlg_cardmi_ban.vue")
  },
  {
    name: "manage-center-components-dlg_cardmi_edit",
    path: "components/dlg_cardmi_edit",
    component: () => import("F:/NodejsProject/Hpapi9/nuxt_server/pages/manage/center/components/dlg_cardmi_edit.vue")
  },
  {
    name: "manage-center-components-dlg_cardmi_hand",
    path: "components/dlg_cardmi_hand",
    component: () => import("F:/NodejsProject/Hpapi9/nuxt_server/pages/manage/center/components/dlg_cardmi_hand.vue")
  },
  {
    name: "manage-center-components-dlg_cardmi_renew",
    path: "components/dlg_cardmi_renew",
    component: () => import("F:/NodejsProject/Hpapi9/nuxt_server/pages/manage/center/components/dlg_cardmi_renew.vue")
  },
  {
    name: "manage-center-components-dlg_cardmi_trans",
    path: "components/dlg_cardmi_trans",
    component: () => import("F:/NodejsProject/Hpapi9/nuxt_server/pages/manage/center/components/dlg_cardmi_trans.vue")
  },
  {
    name: "manage-center-components-dlg_cardmi_unbind",
    path: "components/dlg_cardmi_unbind",
    component: () => import("F:/NodejsProject/Hpapi9/nuxt_server/pages/manage/center/components/dlg_cardmi_unbind.vue")
  },
  {
    name: "manage-center-components-dlg_leves_aed",
    path: "components/dlg_leves_aed",
    component: () => import("F:/NodejsProject/Hpapi9/nuxt_server/pages/manage/center/components/dlg_leves_aed.vue")
  },
  {
    name: "manage-center-components-dlg_paramtemp_aed",
    path: "components/dlg_paramtemp_aed",
    component: () => import("F:/NodejsProject/Hpapi9/nuxt_server/pages/manage/center/components/dlg_paramtemp_aed.vue")
  },
  {
    name: "manage-center-components-dlg_umgt_ed",
    path: "components/dlg_umgt_ed",
    component: () => import("F:/NodejsProject/Hpapi9/nuxt_server/pages/manage/center/components/dlg_umgt_ed.vue")
  },
  {
    name: "manage-center-components-dlg_umgt_recharge",
    path: "components/dlg_umgt_recharge",
    component: () => import("F:/NodejsProject/Hpapi9/nuxt_server/pages/manage/center/components/dlg_umgt_recharge.vue")
  },
  {
    name: "manage-center-components-dlg_umgt_reg",
    path: "components/dlg_umgt_reg",
    component: () => import("F:/NodejsProject/Hpapi9/nuxt_server/pages/manage/center/components/dlg_umgt_reg.vue")
  },
  {
    name: "manage-center",
    path: "",
    component: () => import("F:/NodejsProject/Hpapi9/nuxt_server/pages/manage/center/index.vue")
  }
]
  },
  {
    name: "manage",
    path: "/manage",
    component: () => import("F:/NodejsProject/Hpapi9/nuxt_server/pages/manage/index.vue")
  }
]