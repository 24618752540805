<script setup lang="ts">
import elementZhCn from '@/components/element-zh-cn';


/**
 * 使用阿里图标
 */
useHead({
    script: [
        { src: '//at.alicdn.com/t/c/font_3789453_0yurgdtkq7y.js' }
    ],
    meta: [
        // 解决 IOS 手机中在 textarea 或 input 中输入文字时页面放大
        { name: "viewport", content: "width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0,user-scalable=no" }
    ]
})

/**
 * 当通过页面访问时会生成GetToken,防止直接通过/api/进行请求
 */
if (import.meta.server) {
    const event = useRequestEvent()
    if (event && !event?.context?.session?.GetId) {
        event.context.session.GetId = useRandomString(24)
        await event.context.session.save()
    }
}

</script>


<template>
    <NuxtLoadingIndicator />
    <el-config-provider :locale="elementZhCn" size="default" :z-index="3000">
        <NuxtPage />
    </el-config-provider>
</template>


<style lang="scss">
html,
body,
#app {
    height: 100%;
    margin: 0;
    padding: 0;
    -webkit-text-size-adjust: 100%;
}

/** 全页背景 */
html {
    background: linear-gradient(45deg,
            #e6e6e6,
            #c8dce9 70%,
            #c7d7e9 75%,
            #cbd3e9 85%,
            #cdd2e8 0,
            #dcdce7 90%,
            #e3e4e6 95%);
    background-size: cover;
    background-attachment: fixed;

    #app {
        background-image: url('/bg-transparent.svg');
        background-size: cover;
    }
}

/** 控制自动填充时的输入框背景颜色 */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-transition-delay: 99999s;
    -webkit-transition: color 99999s ease-out, background-color 99999s ease-out;
}

/** 控制自动填充时的输入框文本颜色 */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    -webkit-text-fill-color: var(--el-color-info) !important;
}


/** 默认滚动条样式 */
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    transform: scaleY(-1);
}

::-webkit-scrollbar-track {
    background-color: none;
}

::-webkit-scrollbar-thumb {
    background-color: #0000002e;
    border-radius: 10px;
}


/**
 * 全局自定义class
 */

/** flex布局,垂直居中 */
.flexRow {
    display: flex;
    align-items: center;
    justify-content: center
}

/** flex1 */
.flex1 {
    flex: 1;
}

.flexWrap {
    flex-wrap: wrap
}

.flexGap5 {
    gap: 5px;
}

.flexGap10 {
    gap: 10px;
}

/** 文本超出显示省略号 */
.textEllipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}




/**
 * element 主题
 */

/** 所有圆角 */
:root {
    --m-usual-border-radius: 15px !important;
    --el-border-radius-base: 10px !important;
    --el-border-radius-small: 10px !important;
}

/** el-card */
.el-card {
    border-width: 0 !important;
    --el-card-border-radius: 15px !important;
}

/** 全局对话框 */
.el-dialog {
    max-height: 95%;
    display: flex;
    flex-direction: column;
    padding: 20px !important;
    gap: 20px;

    .el-dialog__header {
        padding: 0 !important;
        display: flex;
        align-items: center;
        gap: 5px;

        .el-dialog__title {
            flex: 1;
            display: flex;
            align-items: center;
            gap: 5px;

            &::before {
                content: '';
                flex-shrink: 0;
                width: 4px;
                height: 15px;
                border-radius: 2px;
                background-color: #4c535a;
                margin-top: 2px;
                margin-right: 5px;
            }
        }

        .el-dialog__headerbtn {
            position: inherit;
            width: 22px;
            height: 22px;
            background-color: var(--el-border-color-darker);
            border: var(--el-border);
            border-color: var(--el-border-color-darker);
            border-radius: 5px;
            box-sizing: border-box;
            color: var(--el-color-white);

            .el-dialog__close {
                width: 100%;
                height: 100%;
                color: #fff;
            }
        }
    }

    .el-dialog__body {
        flex: 1;
        overflow: auto;
        padding: 0;
    }

    .el-dialog__footer {
        padding: 0;
    }

    @media screen and (max-width: 992px) {
        width: 80% !important;
    }

    @media screen and (max-width: 768px) {
        width: 90% !important;
    }

    @media screen and (max-width: 400px) {
        width: 95% !important;
    }
}

/** 全局对话框2 第二个风格 */
.el-dialog2 {
    padding: 0;
    background-color: #bfdfff;
    background-image: url('/bg-transparent.svg');
    background-size: cover;

    .el-dialog__header {
        display: flex;
        align-items: center;
        gap: 5px;
        padding: var(--el-dialog-padding-primary);
        border-radius: var(--el-dialog-border-radius) var(--el-dialog-border-radius) 0 0;

        .icon {
            margin-top: 1px !important;
        }

        .el-dialog__title {
            flex: 1;
            display: flex;
            align-items: center;
            gap: 5px;
        }

        .el-dialog__headerbtn {
            position: inherit;
            width: 20px;
            height: 20px;
            background-color: var(--el-color-primary);
            border: var(--el-border);
            border-color: var(--el-color-primary);
            border-radius: 5px;
            box-sizing: border-box;
            color: var(--el-color-white);

            .el-dialog__close {
                color: #fff;
                margin-top: 1px;
            }
        }

    }

    .el-dialog__body {
        flex: 1;
        overflow: auto;
        padding: var(--el-dialog-padding-primary);
        background-color: #fff;
        border-radius: var(--el-dialog-border-radius) var(--el-dialog-border-radius) 0 0;
        box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 26%);
    }

    .el-dialog__footer {
        padding: var(--el-dialog-padding-primary);
        background-color: #fff;
        border-radius: 0 0 var(--el-dialog-border-radius) var(--el-dialog-border-radius);
        border-top: 1px solid #dcdfe6;
    }
}


/** 消息框圆角 */
.el-message-box {
    --el-messagebox-border-radius: 10px !important;
}


/** 消息框置顶 */
.el-overlay.is-message-box {
    z-index: 5000 !important;
}


/** el-form 最后一项不添加 bootom */
.el-form {
    >*:last-child {
        margin-bottom: 0 !important;
    }
}
</style>
