<script setup lang="ts">
   
</script>

<template>
   index
</template>

<style scoped lang="scss">
   
</style>